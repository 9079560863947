<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Refund Details # {{ details.id }}</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/sales/refunds/list/')" text="Back"/>
            </div>
        </div>
        <div class="card bs-4 p-0 of-h">
            <h5 class="bg-1 p-3">Details</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Order ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.id }}</div>
                <div class="col-2 label">Transaction Id</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.return_date }}</div>
                <div class="col-2 label">Order</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.reason }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1">
                    <validated-vue-select class="mb-0" name="Status" :options="statusOptions" v-model="status"/>
                </div>
                <div class="col-1 mt-2">
                    <btn size="sm" :loading="loading" loading-text="Updating.."
                         @click="changeStatus" text="Update"/>
                </div>
            </div>
            <custom-simple-table class="table-borderless of-h mt-4" ref="table" title="Items"
                                 :fields="fields" :data="details.items"/>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'DetailsComponent',
    data () {
        return {
            DataLoading   : false,
            id            : this.$route.params.id,
            details       : '',
            status        : '',
            statusOptions : [
                { value : 'Return Requested', label : 'Return Requested' },
                { value : 'Return Processing', label : 'Return Processing' },
                { value : 'Return Assigned', label : 'Return Assigned' },
                { value : 'Return Picked', label : 'Return Picked' },
                { value : 'Return Delivered', label : 'Return Delivered' }
            ],
            fields : [
                {
                    name      : 'sku',
                    sortField : 'sku',
                    title     : 'SKU'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Item'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                }
            ],
            loading : false
        };
    },
    mounted () {
        this.loadDetails();
        // this.loadStatus();
    },
    methods : {
        // async loadStatus () {
        //     const that = this;
        //     const response = await axios.form(urls.salesAdmin.orders.entryVueSelect, { order_id : that.id });
        //     const json = response.data;
        //     that.statusOptions = json.data;
        // },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.refunds.details, { return_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.details = json.sale_return;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        async changeStatus () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.refunds.changeStatus, {
                return_id : that.id,
                status    : that.status
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Status Updated Successfully', 'Success', {
                    type : 'success'
                });
            } else {
                that.$notify('Failed to Update Status', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
            that.loading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
